<template>
    <div style="margin-top: 10%">        
        <!-- <div class="row mt-5">
            <div class="col-xs-12 col-sm-10 col-md-6 offset-sm-1 offset-md-3">
                <div class="card card-login">
                    <div class="card-header" style="background-color: #FF9B00; text-align: center;">
                        <img class="card-img-top login-banner" src="@/assets/bge-logo-dark.png" style="padding-top: 1em; padding-bottom: 1em; width: 285px;" />
                    </div>
                    <div class="card-body">
                        <div class="alert alert-danger" v-if="loginStatus == 'Failure'">                            
                            Couldn't log you in, please check your username and password                       
                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Username or Email Address" v-model="username" @keydown.enter="searchFormSubmitted()">
                        </div>
                        <div class="form-group">
                            <input type="password" class="form-control" placeholder="Password" v-model="password" @keydown.enter="formSubmitted()">
                        </div>
                        <button class="btn btn-secondary btn-block btn-lg" v-on:click="login">
                            <span class="fas fa-sign-in-alt"></span> Login
                        </button>
                        <div class="text-center mt-3" v-if="loginStatus == 'InProgress'">
                            <span class="fas fa-spinner fa-spin fa-3x vtms-green"></span>
                        </div>                        
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
    export default {
        name: 'Login',
        created() {
            this.AuthService.login()
        }
        /*data() {
            return {
                username: '',
                password: '',
                loginStatus: 'Pending'
            }            
        },
        methods: {
            async login() {
                this.loginStatus = 'InProgress'
                try {
                    await login(this.username, this.password)   

                    this.loginStatus = 'Success'
                    this.$router.push('/')
                }
                catch (err) {
                    this.loginStatus = 'Failure'                    
                }                
            },
            formSubmitted() {
                this.login()
            }
        }*/
    }
</script>

<style>
</style>

